<template>
  <div class="w-full h-full pr-20">
    <div class="text-c24 font-c5 text-left">
      Bienvenue !
    </div>

    <div class="h-96 bg-f4 rounded-14 p-4 pl-6 flex items-center mt-5">
      <div class="h-16 w-16 rounded-50 imad p-1">
        <img
          src="../../../assets/images/jpg/login.jpg"
          alt=""
          class="w-full h-full rounded-50"
        >
      </div>

      <div class="ml-4 text-left w-1/3">
        <div class="text-c18 font-c5">
          {{ donnes.fullname }}
        </div>
        <div class="text-60 text-c14">
          <span v-if="donnes.type === 'SELL'">Commercial</span>
        </div>
      </div>

      <div class="divider bg-c4 ml-5" />

      <div class="flex items-center ml-5">
        <icon
          :data="icons.agence_green"
          height="30"
          width="30"
          class="mr-2"
          original
        />
        <div class="text-c16 text-43 font-c6 w-full text-left ml-2">
          <span v-if="agence !== null">{{ agence }}</span>
        </div>
      </div>
    </div>

    <div class="flex mt-10">
      <div class="text-left text-c16 font-c4 w-2/3">
        Créer un mot de passe pour votre compte
      </div>

      <div class="text-left text-c16 font-c4 w-1/3 text-right">
        <span
          v-if="typePassword === 'password'"
          class="cursor-pointer"
          @click="afficher"
        >
          Afficher
        </span>
        <span
          v-if="typePassword === 'text'"
          class="cursor-pointer"
          @click="cacher"
        >
          Cacher
        </span>
      </div>
    </div>


    <div class="text-left mt-4">
      <div class="inputo h-60 flex pl-4 items-center rounded-10">
        <icon
          :data="icons.ic_lock"
          height="25"
          width="25"
          class="mr-3"
          original
        />
        <input
          v-model="identifiant"
          :type="typePassword"
          placeholder=". . . ."
          class="h-full w-full text-c18"
        >
      </div>
    </div>

    <div class="flex mt-6">
      <div class="text-left text-c16 font-c4 w-2/3">
        Confirmer
      </div>

      <div class="text-left text-c16 font-c4 w-1/3 text-right">
        <span
          v-if="typePassword2 === 'password'"
          class="cursor-pointer"
          @click="typePassword2 = 'text'"
        >
          Afficher
        </span>
        <span
          v-if="typePassword2 === 'text'"
          class="cursor-pointer"
          @click="typePassword2 = 'password'"
        >
          Cacher
        </span>
      </div>
    </div>


    <div class="text-left mt-4">
      <div class="inputo h-60 flex pl-4 items-center rounded-10">
        <icon
          :data="icons.ic_lock"
          height="25"
          width="25"
          class="mr-3"
          original
        />
        <input
          v-model="password"
          :type="typePassword2"
          placeholder=". . . ."
          class="h-full w-full text-c18"
        >
      </div>
    </div>

    <div
      v-if="error !== null"
      class="mt-4 text-red"
    >
      {{ error }}
    </div>

    <div
      class="mt-6 h-60 bg-oho-blue flex justify-center items-center text-c18 text-white rounded-10 cursor-pointer"
      @click="goNext"
    >
      <div>Connexion</div>
    </div>
  </div>
</template>

<script>
import ic_user from '../../../assets/icons/user.svg'
import ic_lock from '../../../assets/icons/lock.svg'
import agence_green from '../../../assets/icons/agence-green.svg'
import apiroutes from '@/router/api-routes'
import http from '@/plugins/https'

export default {
  name: "Index",
  components: {
  },

  props: {
    donnes: {
      type: Object,
      default: null
    },

    token: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      agence: null,
      identifiant: '',
      password: '',
      error: null,
      typePassword: 'password',
      typePassword2: 'password',
      icons: {
        ic_user,
        ic_lock,
        agence_green
      }
    }
  },
  created () {
    this.getAllAgence()
  },
  methods: {
    afficher(){
      this.typePassword = 'text'
    },

    cacher(){
      this.typePassword = 'password'
    },

    goNext(){
      this.error = null
      if (this.identifiant.length > 0 && this.password.length > 0){
        if (this.identifiant === this.password){
          this.change()
        } else {
          this.error = 'Les mots de passe entrer ne sont pas conformes'
        }
      } else {
        this.error = 'Les deux champs sont obligatoirs'
      }
    },

    getAllAgence () {
      http.get(apiroutes.baseURL + apiroutes.allAgency)
          .then(response => {
            for (let item in response) {
              if (this.donnes.sessionAgency === response[item].id){
                this.agence = response[item].label
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
    },

    change () {
      http.post(apiroutes.baseURL + apiroutes.updatePass, {
        password: this.password
      }, {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      })
          .then(response => {
            this.$store.dispatch('save-token', response.token)
          })
          .catch(error => {
            console.log(error)
          })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";

.inputo{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #DDDDDD;
  box-sizing: border-box;
}
.inputo:focus-within {
  border-color: $focus-color;
}
input{
  background-color: transparent;
}
img{
  object-fit: cover;
}
.imad{
  border: 0.59px dashed #9DB7F9;
  box-sizing: border-box;
}
.divider{
  height: 48px;
  width: 1px;
}
</style>
